document.getElementById('newsletter_signup').addEventListener('click', function () {
    openNewsletterSignup();
});

document.getElementById('close_signup').addEventListener('click', function () {
    closeNewsletterSignup();
});

function closeNewsletterSignup() {
    document.getElementById('mc_embed_signup').style.display = 'none';
    document.body.classList.add('is-scrollable');
}

function openNewsletterSignup() {
    document.getElementById('mc_embed_signup').style.display = '';
    document.body.classList.remove('is-scrollable');
}