$(function () {
    $('.nav-toggle').on('click', function () {
        $('nav').addClass('is-active');
        $('body').removeClass('is-scrollable');
    });
    $('.nav-close').on('click', function () {
        $('body').addClass('is-scrollable');
        $('nav').removeClass('is-active');
    });

    $('.nav__item').each(function() {
        var $navItem = $(this);
        if ($navItem.find('.sub-nav').length > 0) {
            var $navItemLink = $navItem.children('a');
            $navItemLink.prop('href', '#');
            $navItemLink.on('click', function () {
                $('.nav__item').not($navItem).removeClass('is-active');
                $navItem.toggleClass('is-active');
            });
            if ($(window).width() > 768) {
                $navItemLink.on('mouseover', function () {
                    $('.nav__item').not($navItem).removeClass('is-active');
                });
            }
        }
    });

    $('.side-nav__title').on('click', function() {
        $(this).parents('.side-nav').toggleClass('is-active');
    });
});

$(window).on('load', function () {
    setupStickyNav();
    $(window).on('resize', function () {
        setupStickyNav();
    });
});

function setupStickyNav() {
    var $Model = $('.side-nav');
    if ($Model.length <= 0) {
        return;
    }

    // reset sticky nav components
    $Model.parent().css('position', '');
    $Model.css('position', '');
    $Model.css('width', '');
    $Model.css('margin-top', '');
    $(window).off('scroll');

    var ModelInitialHeight = $Model.outerHeight(true);

    if (document.documentElement.clientWidth >= 1024) { // TODO: remove magic number (breakpoint where nav is at side)
        var stickOn = $Model.offset().top - $('header').first().outerHeight(true),
            stickOff = $('footer').offset().top - ModelInitialHeight - $('header').first().outerHeight(true) - 32; // TODO: remove magic number (content-row padding)

        $Model.parent().css('position', 'relative');
        $Model.css('position', 'absolute');
        $Model.css('width', '85%');
        $(window).on('scroll', function () {
            var currentSideNavTop = $Model.offset().top + $Model.css('margin-top');
            var distanceY = window.pageYOffset || document.documentElement.scrollTop;
            if (distanceY > stickOn && distanceY < stickOff) {
                $Model.css('margin-top', (distanceY - stickOn) + 'px');
            } else if (distanceY <= stickOn) {
                $Model.css('margin-top', '');
            } else {
                $Model.css('margin-top', ($Model.parent().outerHeight() - ModelInitialHeight) + 'px');
            }
        });
    }
}