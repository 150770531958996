var bannerSlider;
$(function () {
    var $banners = $('.banners--carousel');
    bannerSlider = $banners.bxSlider({
        pagerCustom: '#banner-nav',
        responsive: true,
        auto: true,
        autoHover: true,
        pause: 7000
    });
});