function initHeader() {
    $('header').addClass('header--fixed');
    $('header').clone().insertBefore('header').removeClass('header--fixed').addClass('header--sentinel');
    var $header = $('header');
    if (!$header.hasClass('header--small')) {
        $(window).on('scroll', function () {
            var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                shrinkOn = 50;
            $header = $('header');
            if (distanceY > shrinkOn) {
                if (!$header.hasClass('header--small')) {
                    $header.addClass('header--small');
                }
            } else {
                $header.removeClass('header--small');
            }
        });
    }
}

$(function () {
    initHeader();
});