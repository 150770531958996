var secondsToRecap = 10;

function showAudioPlayer(playerId) {
    var audioPlayer = document.getElementById(playerId);
    audioPlayer.className += ' is-active';
    audioPlayer.addEventListener('timeupdate', function () {
        setAudioTimeCookie(playerId, audioPlayer.currentTime);
    });
    audioPlayer.play();
}

function showYouTubePlayer(playerId) {
    var videoPlayer = document.getElementById(playerId);
    console.log(playerId);
    videoPlayer.className += ' is-active';
    var playerIframe = videoPlayer.querySelector('iframe');
    playerIframe.src = playerIframe.dataset.src;
}

function setAudioTimeCookie(playerId, time) {
    createCookie(playerId, time, 30);
}

function setAudioPlayerTime() {
    if (this.hasAttribute('data-play-from')) {
        this.currentTime = this.getAttribute('data-play-from');
        this.removeAttribute('data-play-from');
    }
    this.removeEventListener('canplay', setAudioPlayerTime);
}

function downloadAttributeSupported() {
    return typeof document.createElement('a').download != "undefined";
}

(function () {
    var audioPlayers = document.getElementsByTagName('audio');
    for (var i = 0; i < audioPlayers.length; i++) {
        var audioPlayer = audioPlayers[i];
        var time = readCookie(audioPlayer.getAttribute('id'));
        if (time != null && time > secondsToRecap) {
            audioPlayer.setAttribute('data-play-from', time - secondsToRecap);
        }
        audioPlayer.addEventListener('canplay', setAudioPlayerTime);
    }

    if (!downloadAttributeSupported()) {
        var links = document.getElementsByTagName('a');
        for (var i = 0; i < links.length; i++) {
            var link = links[i];
            var download = link.getAttribute('download');
            var rawUrl = link.getAttribute('data-raw-url');
            if (download != null && rawUrl != null) {
                link.setAttribute('href', rawUrl);
            }
        }
    }
})();